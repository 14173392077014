<template>
  <div>
    <lp-datatable
      ref="affiliateRecordsTable"
      title="Referral Order"
      store-module="affiliate"
      store-data-endpoint="getAffiliateOrderList"
      store-getter-endpoint="affiliateOrderList"
      pagination-state-key="affiliateOrderList"
      :features="loadedConfig.datatableFeatures"
      :load-system-constant="true"
      default-search-field="name"
      default-sort-field="created_at"
      :search-options-dropdown="loadedConfig.searchOptsDropdown"
      :conf="loadedConfig"
      @actionBtn-clicked="actionBtnClicked"
    />

    <b-modal
      ref="viewAffiliateRecordsModal"
      hide-footer
      size="lg"
      no-close-on-backdrop
      header-bg-variant="primary"
      header-text-variant="light"
      header-close-variant="dark"
      title="Referral Record"
    >
      <b-table
        striped
        hover
        :fields="fields"
        :items="selectedRowReferralRecord"
        show-empty
      >

        <template #empty>
          <div class="text-center p-2">
            No Data
          </div>
        </template>
      </b-table>
    </b-modal>

    <b-modal
      ref="viewAffiliateUserDataModal"
      hide-footer
      no-close-on-backdrop
      header-bg-variant="primary"
      header-text-variant="light"
      header-close-variant="dark"
      title="User Info"
    >AFFILIATE USER DATA

      {{ selectedRowProfile }}
    </b-modal>
  </div>
</template>

<script>
import Config from '@/views/administration/referralRecords/formConfig'
import humaniseMixin from '@/common/humanise.mixin'
import LpDatatable from '@/leanpay-components/LPDataTable.vue'

export default {
  components: { LpDatatable },
  mixins: [humaniseMixin],
  data() {
    return {
      cardTitle: 'Referral Order',
      dataGridEndpoint: [],
      module: 'affiliate',

      // DataGridSetting
      filter: '',
      searchTerm: '',
      currentPage: 1,
      isBusy: true,
      selectedPerPage: 50,
      sortBy: 'created_at',
      sortDesc: true,

      record_status: 1, // active only
      searchField: 'name',

      selectedRowReferralRecord: [],
      selectedRowProfile: {},

      fields: [
        {
          key: 'affiliate_id',
          sortable: true,
        },
        {
          key: 'affiliate_merchant_account_id',
          sortable: false,
        },
        {
          key: 'transaction_amount',
          sortable: false,
        },
        {
          key: 'transaction_date',
          sortable: false,
        },
        {
          key: 'is_active_account',
          sortable: false,
        },
        {
          key: 'is_paid',
          sortable: false,
        },
      ],

      searchFieldOpts: [
        { value: 'name', text: 'Name' },
        { value: 'nric', text: 'NRIC' },
        { value: 'affiliate_code', text: 'Code' },
        { value: 'account_bank_name', text: 'Account Holder Bank Name' },
      ],
    }
  },
  computed: {
    loadedConfig() {
      if (Config) {
        return Config
      }
      return {}
    },
  },
  async mounted() {
    this.isBusy = false
    const breadcrumbUpdatePayload = [
      { title: 'Referral' },
      { title: 'Referral Order' },
    ]
    await this.$store.dispatch('breadcrumbs/setBreadcrumb', breadcrumbUpdatePayload)
    await this.$store.dispatch('systemConstants/getSystemConstants')

    // this.initDataGrid()
  },
  methods: {
    async actionBtnClicked(e) {
      if (e.actionName === 'approvePayout') {
        alert(`approvePayout${e.rowData.id}`)

        const payload = {
          _id: e.rowData.id,
        }
        await this.$store.dispatch('affiliate/approve_affiliate_payout', payload).then(res => {
          alert(res.response_code)
        })
      }
    },
  },
}
</script>
